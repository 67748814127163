import Vue from 'vue'
import Router from 'vue-router'

import Index from '@/views/index'
import NotFound from '@/views/notFound'
import Manufacturer from "@/views/manufacturer"
import Supplier from '@/views/supplier'

Vue.use(Router)
export const fixedRouter = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    hidden: true
  },
  {
    path: '/',
    redirect: '/index',
    hidden: true
  },
  {
    path: '/index',
    name: '主页',
    component: Index
  },
  {
    path: '/manufacturer',
    name: '制造商',
    component: Manufacturer
  },
  {
    path: '/supplier',
    name: '供应商',
    component: Supplier
  },
]

export default new Router({
  routes: fixedRouter
})

import ElementUI from 'element-ui'

const statusTable = [
  {
    text: "成功",
    status: "Success",
    code: "S0"
  },
  {
    text: "失败",
    status: "Failed",
    code: "F0"
  },

  {
    text: "布尔假",
    status: "BooleanFalse",
    code: "B0"
  },
  {
    text: "布尔真",
    status: "BooleanTrue",
    code: "B1"
  },

  {
    text: "数据已存在",
    status: "DataExist",
    code: "C1"
  },
  {
    text: "数据不存在",
    status: "DataNotExist",
    code: "C2"
  },
  {
    text: "用户名已存在",
    status: "UsernameExist",
    code: "C3"
  },
  {
    text: "用户名不存在",
    status: "UsernameNotExist",
    code: "C4"
  },
  {
    text: "手机号已存在",
    status: "PhoneExist",
    code: "C5"
  },
  {
    text: "手机号不存在",
    status: "PhoneNotExist",
    code: "C6"
  },
  {
    text: "微信浏览器",
    status: "IsWechatBrowser",
    code: "C7"
  },
  {
    text: "不是微信浏览器",
    status: "IsNotWechatBrowser",
    code: "C8"
  },
  {
    text: "密码已设置",
    status: "PasswordSeted",
    code: "C9"
  },
  {
    text: "密码未设置",
    status: "PasswordNotSeted",
    code: "C10"
  },
  {
    text: "收到片段",
    status: "SegmentRecived",
    code: "C11"
  },
  {
    text: "订单等待支付",
    status: "WaitPay",
    code: "C12"
  },
  {
    text: "订单已支付",
    status: "Paied",
    code: "C13"
  },
  {
    text: "订单重新生成",
    status: "Refreshed",
    code: "C14"
  },

  {
    text: "逻辑异常",
    status: "Exception",
    code: "E0"
  },
  {
    text: "无效的操作",
    status: "InvalidOperation",
    code: "E1"
  },
  {
    text: "参数错误",
    status: "ParameterError",
    code: "E2"
  },
  {
    text: "找不到记录",
    status: "NoRecordFound",
    code: "E3"
  },
  {
    text: "数据不完整",
    status: "DataIncompleted",
    code: "E4"
  },
  {
    text: "操作过于频繁",
    status: "RepeatOperate",
    code: "E5"
  },
  {
    text: "验证签名失败",
    status: "VerifyFailed",
    code: "E6"
  },
  {
    text: "数据解密失败",
    status: "DecryptFailed",
    code: "E7"
  },
  {
    text: "超时",
    status: "TimeOut",
    code: "E8"
  },
  {
    text: "没有登录",
    status: "NoLogin",
    code: "E9"
  },
  {
    text: "登录失败",
    status: "LoginFailure",
    code: "E10"
  },
  {
    text: "用户不存在",
    status: "NoUserFound",
    code: "E11"
  },
  {
    text: "客户被禁用",
    status: "ClientDisabled",
    code: "E12"
  },
  {
    text: "用户被禁用",
    status: "UserDisabled",
    code: "E13"
  },
  {
    text: "密码错误",
    status: "WrongPassword",
    code: "E14"
  },
  {
    text: "访问被拒绝",
    status: "AccessDenied",
    code: "E15"
  },
  {
    text: "找不到插件",
    status: "NoPluginFound",
    code: "E16"
  },
  {
    text: "插件未响应",
    status: "NoResponse",
    code: "E17"
  },
  {
    text: "插件响应错误",
    status: "RespondError",
    code: "E18"
  },
  {
    text: "验证码错误",
    status: "VercodeError",
    code: "E19"
  },
  {
    text: "发送验证码过于频繁",
    status: "VercodeFrequent",
    code: "E20"
  },
  {
    text: "获取微信用户失败",
    status: "GetUnionUserFailure",
    code: "E21"
  },
  {
    text: "微信用户已经绑定其他人",
    status: "UnionUserBinded",
    code: "E22"
  },
  {
    text: "授权过期提醒",
    status: "Expired",
    code: "E24"
  }
]

const status = {}

statusTable.forEach(item => {
  status[item.status] = item.code;
})

status.onError = function (data, cb) {
  let content = "";
  statusTable.forEach(item => {
    if (item.code == data.Status) {
      if (data.Message) {
        content = item.text + ":" + data.Message + "!";
      }
      else {
        content = item.text + "!";
      }
    }
  });

  ElementUI.Message({
    message: content,
    offset: 100,
    type: 'error',
    dration: 1000
  });

  if (typeof (cb) === "function") {
    cb();
  }
}


export default status;

<template>
  <div class="comp-footer">
    <div class="footer-container">法律条款声明 | 南京激素企业管理咨询有限公司 Copyright 2020-2022</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped="">
.comp-footer {
  border-top:1px solid #ccc;
  background: #ccc;
  width: 100%;
}

.comp-footer /deep/ .footer-container {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
}
</style>

<template>
  <div class="comp-header">
    <div class="left">
      <img class="image" :src="logo" />
    </div>
    <div class="right">
      <div class="r-top">{{username}}</div>
      <div class="r-bottom">
        <span>中文版</span>
        <span>English Version</span>
        <span>收藏夹</span>
        <span>系统设置</span>
        <span>帮助</span>
      </div>
    </div>
    <div style="clear:both"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  props:{
    logo:{
      type:String,
      require:true,
    },
    username:{
      type:String
    }
  }
};
</script>

<style scoped="">
.comp-header {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ccc;
}

.comp-header /deep/ .left {
  float: left;
  height: 100%;
}

.comp-header /deep/ .left .image {
  height: calc(100% - 6px);
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
}

.comp-header /deep/ .right {
  float: right;
}

.comp-header /deep/ .right .r-top {
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  text-align: right;
}

.comp-header /deep/ .right .r-bottom {
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
}

.comp-header /deep/ .right .r-bottom span {
  margin-left: 10px;
  cursor: pointer;
}
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("./assets/css/reset.css");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}

.dialog .el-dialog {
  margin: 0 auto 0 auto;
}

.el-dialog .el-dialog__body {
  overflow: auto;
  height: calc(100% - 124px);
}

.el-dialog.el-dialog-full-screen .el-dialog__body {
  max-height: inherit;
}

.el-dialog-height-50 {
  height: 50%;
}
.el-dialog-height-80 {
  height: 80%;
}
.el-dialog-height-100 {
  height: 100%;
}

#app a {
  color: #000;
  text-decoration: none;
}
</style>

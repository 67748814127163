import axios from 'axios'

const service = axios.create({
    //baseURL会自动加在接口地址上
    baseURL: process.env.VUE_APP_HOST_URL,
    timeout: 30000,
    withCredentials: true,
});

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    const token = localStorage.getItem('packmanagement_user')
    if (token) {
        config.headers["PackManagement-User"] = token
    }

    return config;
}, function (error) {
    // 对请求错误做些什么

    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么

    if (response.status === 200) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}, function (error) {
    // 对响应错误做点什么

    return Promise.reject(error);
});

export default service

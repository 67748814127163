<template>
    <div>
        对不起，您所访问的页面不存在！！！
        <p>
            <router-link to="/login">跳转到登陆页面</router-link>
        </p>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        }
    }
</script>

<style>
</style>

<template>
  <div class="main" style="height: 100%">
    <comp_header username="Marube" logo="/img/Marube2.png"></comp_header>

    <div class="main-container">
      <div class="container">
        <el-row class="cont" :gutter="50">
          <el-col :xs="24" :sm="24" :md="16" :lg="16">
            <div><span class="title">包装提案管理</span></div>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <div class="item" data-aos="zoom-in">
                  <b-icon class="icon" icon="layer-forward "></b-icon>
                  <div class="text">包装方案提交</div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <div class="item" data-aos="zoom-in">
                  <b-icon class="icon" icon="search"></b-icon>
                  <div class="text">包装方案搜索</div>
                </div>
              </el-col>
            </el-row>
            <div><span class="title">数据服务</span></div>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <div class="item" data-aos="zoom-in">
                  <b-icon class="icon" icon="stack"></b-icon>
                  <div class="text">批量数据导出</div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <div class="item" data-aos="zoom-in">
                  <b-icon class="icon" icon="megaphone-fill"></b-icon>
                  <div class="text">系统通知</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <div class="help" data-aos="zoom-in">
              <div style="margin-top: 20px; margin-bottom: 20px">系统服务</div>
              <div style="line-height: 32px">电话：@4009958182</div>
              <div style="line-height: 32px; cursor: pointer">在线服务</div>
              <div style="line-height: 32px; cursor: pointer">
                <a href="mailto:customer-service@superspeedconsulting.com"
                  >邮件服务</a
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <comp_footer></comp_footer>
  </div>
</template>

<script>
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import AOS from "aos/dist/aos.js";
import comp_header from "@/components/comp/header.vue";
import comp_footer from "@/components/comp/footer.vue";
export default {
  components: {
    comp_header,
    comp_footer,
  },
  data() {
    return {};
  },
  mounted() {
    AOS.init({
      duration: 1200,
    });
  },
};
</script>

<style scoped="">
.main-container {
  min-height: calc(100% - 102px);
}

.main-container /deep/ .cont {
  padding-top: calc(50% - 560px);
}

.main-container /deep/ .title {
  margin-top: 20px;
  border-bottom: 1px solid #000;
  font-size: 20px;
}

.main-container /deep/ .item {
  height: 140px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
}

.main-container /deep/ .item .icon {
  height: 60px;
  line-height: 60px;
  font-size: 35px;
}

.main-container /deep/ .item .text {
  height: 50px;
}

.main-container /deep/ .item:hover {
  box-shadow: 0 0 3px 3px #eee;
  transition: 0.3s;
}

.main-container /deep/ .help {
  text-align: center;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
}
</style>

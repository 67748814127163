import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import router from './router'
import service from './service'
import status from './js/statusenum'
import elDialogDrag from './js/el-dialog-drag'
import elDialogResize from './js/el-dialog-resize'

import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/index.css"
import { BootstrapVue,IconsPlugin} from 'bootstrap-vue'

Vue.config.productionTip = false

//加载窗体拖动、修改大小
Vue.directive('el-dialog-drag', elDialogDrag);
Vue.directive('el-dialog-resize', elDialogResize);
ElementUI.Dialog.props.closeOnClickModal.default = false;

Vue.use(ElementUI)
Vue.prototype.service = service 
Vue.prototype.status = status;
Vue.prototype.serverUrl = process.env.VUE_APP_HOST_URL;

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
